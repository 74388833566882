import { AppDispatch, RootState } from '@/projects/shuttle/store/store';
import { showToasterWithDelay } from '@/store/slices/toasterSlice';
import { axiosInstance } from '@/utils/AxiosInstance';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

type tourSearchData = {
  from_address: AddressType;
  to_address: AddressType;
  phone: string;
  date?: string;
  desired_time?: string;
  transportation_request_id?: number
};

export type toursSearchResponse = {
  tours: tourType[];
  from_address: AddressType;
  to_address: AddressType;
};

export const searchToursAsync = createAsyncThunk<
toursSearchResponse,
tourSearchData,
{
  dispatch: AppDispatch;
  rejectValue: string;
}
>(
  'tours/SearchToursAsync',
  async (data: tourSearchData, { dispatch, rejectWithValue }) => {
    return await axiosInstance
      .post<toursSearchResponse>('/search/tours', data)
      .then((data): toursSearchResponse => data)
      .catch(() => {
        dispatch(
          showToasterWithDelay({
            type: 'warning',
            message: 'Ошибка при получении списка туров',
            isVisible: true,
          })
        );
        return rejectWithValue('Ошибка при получении списка туров');
      });
  }
);

type TourListType = {
  [key: string]: tourDataType[];
};

interface ToursState {
  tours: [] | tourDataType[];
  tourList: TourListType;
  pickupStopId: number | null;
  dropOffStopId: number | null;
  currentTour: tourDataType | null
}

const initialState: ToursState = {
  tours: [
    {
      from_address: {
        name: 'БЦ "ARYS"',
        location: {
          lat: 43.226638826415225,
          lng: 76.95086002725802
        }
      },
      to_address: {
        name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
        location: {
          lat: 43.23145,
          lng: 76.94414
        }
      },
      tour: {
        id: 322993,
        date: '2025-04-04',
        pickup_bus_stop: {
          id: 1318,
          name: 'БЦ "ARYS"',
          location: {
            lat: 43.226638826415225,
            lng: 76.95086002725802
          },
          delay_minutes: 0,
          arrival_time: '08:42'
        },
        dropoff_bus_stop: {
          id: 1320,
          name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
          location: {
            lat: 43.23145,
            lng: 76.94414
          },
          delay_minutes: 0,
          arrival_time: '08:45'
        },
        response_id: 752738,
        route_direction: 'to',
        route_desired_time: '09:00',
        route_bus_stops: [
          {
            id: 1318,
            location: {
              lat: 43.226638826415225,
              lng: 76.95086002725802
            }
          },
          {
            id: 7976,
            location: {
              lat: 43.229579,
              lng: 76.947997
            }
          },
          {
            id: 7977,
            location: {
              lat: 43.232406,
              lng: 76.947588
            }
          },
          {
            id: 1320,
            location: {
              lat: 43.23145,
              lng: 76.94414
            }
          }
        ],
        route_number: '204',
        driver: {
          name: 'Ислан',
          photo_url: 'https://storage.yandexcloud.kz/uvu/media/CACHE/images/user/photos/USN00358/9904f999facdf06276cf3d7da134dcd0.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=YCB2BxiR-FTv3js2nfsiZAYG6%2F20250402%2Fkz1%2Fs3%2Faws4_request&X-Amz-Date=20250402T063357Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=2a276ff21f86793502e8780e81e9f0f7fd76331ccf4db8905882b85d3bc5c156',
          car_name: 'Белый Jac Sunray',
          car_number_plate: '145EV02'
        }
      }
    },
    {
      from_address: {
        name: 'БЦ "ARYS"',
        location: {
          lat: 43.226638826415225,
          lng: 76.95086002725802
        }
      },
      to_address: {
        name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
        location: {
          lat: 43.23145,
          lng: 76.94414
        }
      },
      tour: {
        id: 323123,
        date: '2025-04-04',
        pickup_bus_stop: {
          id: 1318,
          name: 'БЦ "ARYS"',
          location: {
            lat: 43.226638826415225,
            lng: 76.95086002725802
          },
          delay_minutes: 0,
          arrival_time: '08:44'
        },
        dropoff_bus_stop: {
          id: 1320,
          name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
          location: {
            lat: 43.23145,
            lng: 76.94414
          },
          delay_minutes: 0,
          arrival_time: '08:47'
        },
        response_id: 752739,
        route_direction: 'to',
        route_desired_time: '09:00',
        route_bus_stops: [
          {
            id: 1318,
            location: {
              lat: 43.226638826415225,
              lng: 76.95086002725802
            }
          },
          {
            id: 7976,
            location: {
              lat: 43.229579,
              lng: 76.947997
            }
          },
          {
            id: 7977,
            location: {
              lat: 43.232406,
              lng: 76.947588
            }
          },
          {
            id: 1320,
            location: {
              lat: 43.23145,
              lng: 76.94414
            }
          }
        ],
        route_number: '13 ',
        driver: {
          name: 'Ернар',
          photo_url: 'https://storage.yandexcloud.kz/uvu/media/CACHE/images/user/photos/USN00362_resized/1d418e6e40ef9e5767a9423f680382aa.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=YCB2BxiR-FTv3js2nfsiZAYG6%2F20250402%2Fkz1%2Fs3%2Faws4_request&X-Amz-Date=20250402T063357Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=22b94e0134066b72080618a3197057bbf07967b6a8ee0e74c4d1f606083c51c2',
          car_name: 'Белый Jac Sunray',
          car_number_plate: '081EV02'
        }
      }
    },
    {
      from_address: {
        name: 'БЦ "ARYS"',
        location: {
          lat: 43.226638826415225,
          lng: 76.95086002725802
        }
      },
      to_address: {
        name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
        location: {
          lat: 43.23145,
          lng: 76.94414
        }
      },
      tour: {
        id: 314082,
        date: '2025-04-04',
        pickup_bus_stop: {
          id: 1318,
          name: 'БЦ "ARYS"',
          location: {
            lat: 43.226638826415225,
            lng: 76.95086002725802
          },
          delay_minutes: 0,
          arrival_time: '08:51'
        },
        dropoff_bus_stop: {
          id: 1320,
          name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
          location: {
            lat: 43.23145,
            lng: 76.94414
          },
          delay_minutes: 0,
          arrival_time: '08:54'
        },
        response_id: 752740,
        route_direction: 'to',
        route_desired_time: '09:00',
        route_bus_stops: [
          {
            id: 1318,
            location: {
              lat: 43.226638826415225,
              lng: 76.95086002725802
            }
          },
          {
            id: 7976,
            location: {
              lat: 43.229579,
              lng: 76.947997
            }
          },
          {
            id: 7977,
            location: {
              lat: 43.232406,
              lng: 76.947588
            }
          },
          {
            id: 1320,
            location: {
              lat: 43.23145,
              lng: 76.94414
            }
          }
        ],
        route_number: '202',
        driver: {
          name: 'Акжан',
          photo_url: 'https://storage.yandexcloud.kz/uvu/media/CACHE/images/user/photos/USN00349/c8a37c255aff1a73c0660afb860ae303.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=YCB2BxiR-FTv3js2nfsiZAYG6%2F20250402%2Fkz1%2Fs3%2Faws4_request&X-Amz-Date=20250402T063357Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=087c90380299d83f48eebf11bbf4b1f9ba087c9552b4de7dc5a0907fccd06e08',
          car_name: 'Белый Jac Sunray',
          car_number_plate: '145FH02'
        }
      }
    },
    {
      from_address: {
        name: 'БЦ "ARYS"',
        location: {
          lat: 43.226638826415225,
          lng: 76.95086002725802
        }
      },
      to_address: {
        name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
        location: {
          lat: 43.23145,
          lng: 76.94414
        }
      },
      tour: {
        id: 314990,
        date: '2025-04-04',
        pickup_bus_stop: {
          id: 1318,
          name: 'БЦ "ARYS"',
          location: {
            lat: 43.226638826415225,
            lng: 76.95086002725802
          },
          delay_minutes: 0,
          arrival_time: '08:52'
        },
        dropoff_bus_stop: {
          id: 1320,
          name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
          location: {
            lat: 43.23145,
            lng: 76.94414
          },
          delay_minutes: 0,
          arrival_time: '08:55'
        },
        response_id: 752741,
        route_direction: 'to',
        route_desired_time: '09:00',
        route_bus_stops: [
          {
            id: 1318,
            location: {
              lat: 43.226638826415225,
              lng: 76.95086002725802
            }
          },
          {
            id: 7976,
            location: {
              lat: 43.229579,
              lng: 76.947997
            }
          },
          {
            id: 7977,
            location: {
              lat: 43.232406,
              lng: 76.947588
            }
          },
          {
            id: 1320,
            location: {
              lat: 43.23145,
              lng: 76.94414
            }
          }
        ],
        route_number: '405',
        driver: {
          name: 'Адилет',
          photo_url: 'https://storage.yandexcloud.kz/uvu/media/CACHE/images/user/photos/983ca96f-c834-4b5f-bcfa-2b64f3a53ec1/b1c7a032f5e4c0089fbbc4b46f4f74a0.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=YCB2BxiR-FTv3js2nfsiZAYG6%2F20250402%2Fkz1%2Fs3%2Faws4_request&X-Amz-Date=20250402T063357Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=6868f0fce42d7ad28d8e06795cc3e2a6280c9d76dadc2515ee345a456ded497a',
          car_name: 'Белый Jac Sunray',
          car_number_plate: '987FD02'
        }
      }
    },
    {
      from_address: {
        name: 'БЦ "ARYS"',
        location: {
          lat: 43.226638826415225,
          lng: 76.95086002725802
        }
      },
      to_address: {
        name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
        location: {
          lat: 43.23145,
          lng: 76.94414
        }
      },
      tour: {
        id: 323122,
        date: '2025-04-04',
        pickup_bus_stop: {
          id: 1318,
          name: 'БЦ "ARYS"',
          location: {
            lat: 43.226638826415225,
            lng: 76.95086002725802
          },
          delay_minutes: 0,
          arrival_time: '08:46'
        },
        dropoff_bus_stop: {
          id: 1320,
          name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
          location: {
            lat: 43.23145,
            lng: 76.94414
          },
          delay_minutes: 0,
          arrival_time: '08:50'
        },
        response_id: 752742,
        route_direction: 'to',
        route_desired_time: '09:00',
        route_bus_stops: [
          {
            id: 1318,
            location: {
              lat: 43.226638826415225,
              lng: 76.95086002725802
            }
          },
          {
            id: 7976,
            location: {
              lat: 43.229579,
              lng: 76.947997
            }
          },
          {
            id: 7977,
            location: {
              lat: 43.232406,
              lng: 76.947588
            }
          },
          {
            id: 1320,
            location: {
              lat: 43.23145,
              lng: 76.94414
            }
          }
        ],
        route_number: '301',
        driver: {
          name: 'Женис',
          photo_url: 'https://storage.yandexcloud.kz/uvu/media/CACHE/images/user/photos/Screenshot_2024-08-31_at_21.14.06/e97abd5867d854a89f74907376506990.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=YCB2BxiR-FTv3js2nfsiZAYG6%2F20250402%2Fkz1%2Fs3%2Faws4_request&X-Amz-Date=20250402T063357Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=b21a2a37d4b2868d01dd9d893aed27dafe7ecf4a920f67fce0b80cf7834db4bc',
          car_name: 'Белый Jac Sunray',
          car_number_plate: '360DH02'
        }
      }
    },
    {
      from_address: {
        name: 'БЦ "ARYS"',
        location: {
          lat: 43.226638826415225,
          lng: 76.95086002725802
        }
      },
      to_address: {
        name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
        location: {
          lat: 43.23145,
          lng: 76.94414
        }
      },
      tour: {
        id: 314084,
        date: '2025-04-04',
        pickup_bus_stop: {
          id: 1318,
          name: 'БЦ "ARYS"',
          location: {
            lat: 43.226638826415225,
            lng: 76.95086002725802
          },
          delay_minutes: 0,
          arrival_time: '08:46'
        },
        dropoff_bus_stop: {
          id: 1320,
          name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
          location: {
            lat: 43.23145,
            lng: 76.94414
          },
          delay_minutes: 0,
          arrival_time: '08:50'
        },
        response_id: 752743,
        route_direction: 'to',
        route_desired_time: '09:00',
        route_bus_stops: [
          {
            id: 1318,
            location: {
              lat: 43.226638826415225,
              lng: 76.95086002725802
            }
          },
          {
            id: 7976,
            location: {
              lat: 43.229579,
              lng: 76.947997
            }
          },
          {
            id: 7977,
            location: {
              lat: 43.232406,
              lng: 76.947588
            }
          },
          {
            id: 1320,
            location: {
              lat: 43.23145,
              lng: 76.94414
            }
          }
        ],
        route_number: '202',
        driver: {
          name: 'Нурлан',
          photo_url: 'https://storage.yandexcloud.kz/uvu/media/CACHE/images/user/photos/USN00315_resized/b42721f29e479e73063b795e2bdf6234.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=YCB2BxiR-FTv3js2nfsiZAYG6%2F20250402%2Fkz1%2Fs3%2Faws4_request&X-Amz-Date=20250402T063357Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=7b4ccb5061fd1dcb77d61f01d01f3ba8e0d87a91acbfe3c07f6092557da35d63',
          car_name: 'Белый jac sunray',
          car_number_plate: '680FJ02'
        }
      }
    },
    {
      from_address: {
        name: 'БЦ "ARYS"',
        location: {
          lat: 43.226638826415225,
          lng: 76.95086002725802
        }
      },
      to_address: {
        name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
        location: {
          lat: 43.23145,
          lng: 76.94414
        }
      },
      tour: {
        id: 315462,
        date: '2025-04-04',
        pickup_bus_stop: {
          id: 1318,
          name: 'БЦ "ARYS"',
          location: {
            lat: 43.226638826415225,
            lng: 76.95086002725802
          },
          delay_minutes: 0,
          arrival_time: '08:46'
        },
        dropoff_bus_stop: {
          id: 1320,
          name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
          location: {
            lat: 43.23145,
            lng: 76.94414
          },
          delay_minutes: 0,
          arrival_time: '08:50'
        },
        response_id: 752744,
        route_direction: 'to',
        route_desired_time: '09:00',
        route_bus_stops: [
          {
            id: 1318,
            location: {
              lat: 43.226638826415225,
              lng: 76.95086002725802
            }
          },
          {
            id: 7976,
            location: {
              lat: 43.229579,
              lng: 76.947997
            }
          },
          {
            id: 7977,
            location: {
              lat: 43.232406,
              lng: 76.947588
            }
          },
          {
            id: 1320,
            location: {
              lat: 43.23145,
              lng: 76.94414
            }
          }
        ],
        route_number: '205',
        driver: {
          name: 'Вадим',
          photo_url: 'https://storage.yandexcloud.kz/uvu/media/CACHE/images/user/photos/%D0%B2%D0%B0%D0%B4%D0%B8%D0%BC_%D1%84%D0%BE%D1%82%D0%BE_%D0%BD%D0%BE%D0%B2%D0%BE%D0%B5/45723eecb8c266475b570e6df7037f7a.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=YCB2BxiR-FTv3js2nfsiZAYG6%2F20250402%2Fkz1%2Fs3%2Faws4_request&X-Amz-Date=20250402T063357Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb68861af4a55e1e97b790b87bef743ea112f5a78b47d5f627a615b4ae272cab',
          car_name: 'Белый Mercedes Sprinter',
          car_number_plate: '988FMA02'
        }
      }
    },
    {
      from_address: {
        name: 'БЦ "ARYS"',
        location: {
          lat: 43.226638826415225,
          lng: 76.95086002725802
        }
      },
      to_address: {
        name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
        location: {
          lat: 43.23145,
          lng: 76.94414
        }
      },
      tour: {
        id: 322958,
        date: '2025-04-04',
        pickup_bus_stop: {
          id: 1318,
          name: 'БЦ "ARYS"',
          location: {
            lat: 43.226638826415225,
            lng: 76.95086002725802
          },
          delay_minutes: 0,
          arrival_time: '08:51'
        },
        dropoff_bus_stop: {
          id: 1320,
          name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
          location: {
            lat: 43.23145,
            lng: 76.94414
          },
          delay_minutes: 0,
          arrival_time: '08:55'
        },
        response_id: 752745,
        route_direction: 'to',
        route_desired_time: '09:00',
        route_bus_stops: [
          {
            id: 1318,
            location: {
              lat: 43.226638826415225,
              lng: 76.95086002725802
            }
          },
          {
            id: 7976,
            location: {
              lat: 43.229579,
              lng: 76.947997
            }
          },
          {
            id: 7977,
            location: {
              lat: 43.232406,
              lng: 76.947588
            }
          },
          {
            id: 1320,
            location: {
              lat: 43.23145,
              lng: 76.94414
            }
          }
        ],
        route_number: '12 ',
        driver: {
          name: 'Бакытжан',
          photo_url: null,
          car_name: 'Серебристный-металлик Toyota Hiace',
          car_number_plate: '878BQB02'
        }
      }
    },
    {
      from_address: {
        name: 'БЦ "ARYS"',
        location: {
          lat: 43.226638826415225,
          lng: 76.95086002725802
        }
      },
      to_address: {
        name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
        location: {
          lat: 43.23145,
          lng: 76.94414
        }
      },
      tour: {
        id: 323121,
        date: '2025-04-04',
        pickup_bus_stop: {
          id: 1318,
          name: 'БЦ "ARYS"',
          location: {
            lat: 43.226638826415225,
            lng: 76.95086002725802
          },
          delay_minutes: 0,
          arrival_time: '08:40'
        },
        dropoff_bus_stop: {
          id: 1320,
          name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
          location: {
            lat: 43.23145,
            lng: 76.94414
          },
          delay_minutes: 0,
          arrival_time: '08:49'
        },
        response_id: 752746,
        route_direction: 'to',
        route_desired_time: '09:00',
        route_bus_stops: [
          {
            id: 1318,
            location: {
              lat: 43.226638826415225,
              lng: 76.95086002725802
            }
          },
          {
            id: 7976,
            location: {
              lat: 43.229579,
              lng: 76.947997
            }
          },
          {
            id: 7977,
            location: {
              lat: 43.232406,
              lng: 76.947588
            }
          },
          {
            id: 1320,
            location: {
              lat: 43.23145,
              lng: 76.94414
            }
          }
        ],
        route_number: '304',
      }
    },
    {
      from_address: {
        name: 'БЦ "ARYS"',
        location: {
          lat: 43.226638826415225,
          lng: 76.95086002725802
        }
      },
      to_address: {
        name: 'БЦ "Нурлы-Тау" / БЦ "Q"',
        location: {
          lat: 43.23145,
          lng: 76.94414
        }
      },
      tour: {
        id: 322936,
        date: '2025-04-04',
        pickup_bus_stop: {
          id: 1318,
          name: 'БЦ "ARYS"',
          location: {
            lat: 43.226638826415225,
            lng: 76.95086002725802
          },
          delay_minutes: 0,
          arrival_time: '08:51'
        },
        dropoff_bus_stop: {
          id: 2222,
          name: 'БЦ "Нурлы Тау" (Аль-Фараби)',
          location: {
            lat: 43.228215,
            lng: 76.945623
          },
          delay_minutes: 3,
          arrival_time: '08:54'
        },
        response_id: 752747,
        route_direction: 'to',
        route_desired_time: '09:00',
        route_bus_stops: [
          {
            id: 1318,
            location: {
              lat: 43.226638826415225,
              lng: 76.95086002725802
            }
          },
          {
            id: 2222,
            location: {
              lat: 43.228215,
              lng: 76.945623
            }
          }
        ],
        route_number: '6 А',
        driver: {
          name: 'Талгат',
          photo_url: 'https://storage.yandexcloud.kz/uvu/media/CACHE/images/user/photos/Screenshot_2_UQX4MOM/05de3ff8744b4edbe994ebbcc7e1e878.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=YCB2BxiR-FTv3js2nfsiZAYG6%2F20250402%2Fkz1%2Fs3%2Faws4_request&X-Amz-Date=20250402T063357Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f941ffac02a1e37c259edcfcee0c1794048b1e83b1837a6c754717791ab1933b',
          car_name: 'Белый Huinday Starex',
          car_number_plate: '819BBM05'
        }
      }
    }
  ],
  tourList: {},
  pickupStopId: null,
  dropOffStopId: null,
  currentTour: null
};

export const toursSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setTours: (state, action) => {
      state.tours = action.payload;
    },
    setCurrentTour: (state, action) => {
      state.currentTour = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchToursAsync.fulfilled, (state, action) => {
      const response = action.payload as toursSearchResponse;
      const { tours, from_address, to_address } = response;

      if (tours.length) {
        state.tours = tours.map((tour: tourType) => {
          return {
            from_address,
            to_address,
            tour,
          } as tourDataType;
        });
        // state.tourList = {};
        // for (const tour of tours) {
        //   const tourData = { from_address, to_address, tour } as tourDataType;
        //   if (!(tour.route_desired_time in state.tourList)) {
        //     state.tourList[tour.route_desired_time] = [tourData];
        //   } else {
        //     state.tourList[tour.route_desired_time].push(tourData);
        //   }
        // }
        // state.pickupStopId = tours[0].pickup_bus_stop.id;
        state.dropOffStopId = tours[0].dropoff_bus_stop.id;
      }
    });
    builder.addCase(searchToursAsync.rejected, () => {});
  },
});

export const { setTours, setCurrentTour } = toursSlice.actions;

export const toursSelector = (state: RootState) => state.toursReducer;

export default toursSlice.reducer;
